import React, { useState } from 'react'
import { type IQuestion, Operator } from '../../types/questions/Question'
import { Box, Container, Input } from '@mui/material'
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded'

export default function QuestionDisplay ({ question, interactiveMode }: { question: IQuestion, interactiveMode: boolean }): React.JSX.Element {
  const [answeredCorrectly, setAnsweredCorrectly] = useState<boolean>(false)

  const symbolText = getSymbolHTML(question.operator)
  let num1 = question.fact
  let num2 = question.num2

  // Randomize the order of the numbers for addition and multiplication
  if (question.operator === Operator.Addition || question.operator === Operator.Multiplication) {
    const randomNumber = Math.floor(Math.random() * 2) + 1
    if (randomNumber === 2) {
      num1 = question.num2
      num2 = question.fact
    }
  } else if (question.operator === Operator.Division) {
    num1 = question.num2
    num2 = question.fact
  }

  const checkAnswer = (answer: string): void => {
    const numAnswer = parseInt(answer)
    setAnsweredCorrectly(numAnswer === question.getAnswer())
  }

  return (
      <Container
      className='question-item'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignContent: 'right',
          alignItems: 'right',
          paddingBottom: '40px',
          width: '65px'
        }} disableGutters>
        <Box
          sx={{
            textAlign: 'center',
            display: 'block'
          }}>
          {num1}
        </Box>
        <Box
          sx={{
            display: 'flex'
          }}>
          <Box
          flex="1"
            sx={{
              justifySelf: 'left'
            }}>
            {symbolText}
          </Box>
        <Box
          sx={{
            justifySelf: 'center'
          }}>
            {num2}
          </Box>
        <Box flex="1" />
        </Box>
        <Box
          sx={{
            borderTop: '1px solid black'
          }}>
            { interactiveMode && !answeredCorrectly
              ? <Input id="answerInput" inputProps={{ style: { textAlign: 'center' } }}
              sx={{ displayPrint: 'none' }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => { checkAnswer(e.target.value) }} />
              : null}
        {interactiveMode && answeredCorrectly
          ? <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexWrap: 'wrap'
            }}>
            <div />

            <span>{question.getAnswer()}</span>
            <CheckCircleOutlineRoundedIcon sx={{ mt: '4px', mb: '4px' }} color="success" />
          </Box>
          : null}
        </Box>
      </Container>
  )
};

function getSymbolHTML (operator: Operator): string {
  switch (operator) {
    case Operator.Addition:
      return '\u002B'
    case Operator.Division:
      return '\u00F7'
    case Operator.Multiplication:
      return '\u00D7'
    case Operator.Subtraction:
      return '\u2212'
  }

  throw new Error('Operator not supported.')
};
