import { type IQuestion, type Operator } from '../../types/questions/Question'
import { QuestionGeneratorFactory } from './QuestionGeneratorFactory'

export default class QuestionGenerator {
  factNumber: number
  numQuestions: number
  symbol: Operator

  constructor (factNumber: number, numQuestions: number, symbol: Operator) {
    this.factNumber = factNumber
    this.numQuestions = numQuestions
    this.symbol = symbol
  }

  generateQuestions (): IQuestion[] {
    const factory = new QuestionGeneratorFactory()
    return factory.createQuestions(this.factNumber, this.symbol, this.numQuestions)
  }
}
