import React, { useState } from 'react'

// MUI
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

// Components
import { type IQuestion, Operator } from './types/questions/Question'
import QuestionGenerator from './helpers/questions/QuestionGenerator'
import { type IQuestionFormProps, QuestionForm } from './components/questions/QuestionForm'

import StudentInformationFields from './components/student/StudentInformationFields'
import { Hero } from './components/Hero'
import { Footer } from './components/Footer'
import QuestionGrid from './components/QuestionGrid'

export default function App (): JSX.Element {
  const [mode, setInteractiveMode] = useState<{ interactiveMode: boolean }>({ interactiveMode: false })
  const [state, setQuestions] = useState<{ questions: IQuestion[] }>({ questions: [] })

  const handleSubmitForm = (data: IQuestionFormProps): void => {
    const fact = data.factNumber
    const count = data.numQuestions
    const operation = Operator[data.symbol as keyof typeof Operator]

    setInteractiveMode({ interactiveMode: data.interactiveMode })

    const generatedQuestions = new QuestionGenerator(fact, count, operation)
    setQuestions({ questions: generatedQuestions.generateQuestions() })
  }

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? 'dark' : 'light'
        },
        components: {
          MuiContainer: {
            styleOverrides: {
              root: {
                lineHeight: 1.3
              }
            }
          }
        }
      }),
    [prefersDarkMode]
  )
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <header>
        <Hero />
        <QuestionForm onSubmit={handleSubmitForm} />
        <StudentInformationFields />
      </header>
      <main>
        <QuestionGrid questions={state.questions} interactiveMode={mode.interactiveMode} />
      </main>
      <Footer />
    </ThemeProvider>
  )
}
