import React from 'react'
import { Grid } from '@mui/material'
import { type IQuestion } from '../types/questions/Question'
import QuestionDisplay from '../components/questions/QuestionDisplay'

interface QuestionGridProps {
  questions: IQuestion[]
  interactiveMode: boolean
}

export default function QuestionGrid (props: QuestionGridProps): React.JSX.Element {
  return (<Grid container
          className= 'question-grid'
            sx={{
              display: 'grid',
              gridTemplateColumns: { xs: 'repeat(5, 1fr)', md: 'repeat(7, 1fr)', lg: 'repeat(10, 1fr)' },
              placeItems: 'center',
              flexDirection: 'column',
              '@media print': {
                gridTemplateColumns: 'repeat(10, 1fr)'
              }
            }}>
      {
        props.questions.map((q: IQuestion, index: number) => (
          <QuestionDisplay key={index} question={q} interactiveMode={props.interactiveMode} />
        ))
      }
    </Grid >
  )
}
