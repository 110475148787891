import React, { useState } from 'react'
import { Container, Checkbox, FormControl, FormControlLabel, Input, InputLabel, FormLabel, FormHelperText, Select, Button, MenuItem } from '@mui/material'

export interface IQuestionFormProps {
  factNumber: number
  numQuestions: number
  symbol: string
  interactiveMode: boolean
};

export function QuestionForm ({ onSubmit }: { onSubmit: (data: IQuestionFormProps) => void }): JSX.Element {
  const [factNumber, setFactNumber] = useState('')
  const [numQuestions, setNumQuestions] = useState('')
  const [symbol, setSymbol] = useState<string>('Multiplication')
  const [interactiveMode, setInteractiveMode] = useState<boolean>(false)

  const handleSubmit = (e: React.SyntheticEvent<HTMLFormElement>): void => {
    e.preventDefault()

    const iFactNumber: number = parseInt(factNumber)
    const iNumQuestions: number = parseInt(numQuestions)

    if (iFactNumber > 0 && iNumQuestions > 0) {
      onSubmit({ factNumber: iFactNumber, numQuestions: iNumQuestions, symbol, interactiveMode })
    }
  }

  return (

    <form onSubmit={handleSubmit}>
      <Container
        id="question-form"
        maxWidth="lg"
        sx={{
          display: 'flex',
          bgcolor: 'background.paper',
          boxShadow: 2,
          borderRadius: 2,
          flexDirection: 'row',
          alignItems: 'center',
          pt: 4,
          pb: 4,
          mb: 4,
          displayPrint: 'none',
          justifyContent: 'center',
          flexWrap: 'wrap'
        }}
      >
        <FormControl
          sx={{
            minWidth: 120,
            m: 1,
            pl: 2,
            pr: 2
          }}
        >
          <InputLabel htmlFor="factNumber">Fact:</InputLabel>
          <Input
            id="factNumber"
            aria-describedby="factNumber-helper-text"
            type="number"
            value={factNumber}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setFactNumber(e.target.value) }}
          />
          <FormHelperText id="factNumber-helper-text">What fact would you like to practice? </FormHelperText>
        </FormControl>

        <FormControl
          sx={{
            minWidth: 120,
            m: 1,
            pl: 2,
            pr: 2
          }}>
          <InputLabel htmlFor="numQuestions"># of Questions:</InputLabel>
          <Input
            id="numQuestions"
            aria-describedby="numQuestions-helper-text"
            type="number"
            value={numQuestions}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setNumQuestions(e.target.value) }}
          />
          <FormHelperText id="numQuestions-helper-text">How many questions would you like? (up to 100)</FormHelperText>
        </FormControl>

        <FormControl
          sx={{
            minWidth: 120,
            m: 1,
            pl: 2,
            pr: 2
          }}>
          <FormLabel htmlFor="symbol">Operation:</FormLabel>
          <Select
            id="symbol"
            aria-describedby="symbol-helper-text"
            value={symbol}
            onChange={(e) => { setSymbol(e.target.value) }}
          >
            <MenuItem value="Addition">Addition</MenuItem>
            <MenuItem value="Subtraction">Subtraction</MenuItem>
            <MenuItem value="Multiplication" defaultValue="Multiplication">Multiplication</MenuItem>
            <MenuItem value="Division">Division</MenuItem>
          </Select>
          <FormHelperText id="symbol-helper-text">What operator do you want to practice?</FormHelperText>
        </FormControl>
        <FormControl>
          <FormControlLabel control={<Checkbox id="interactive" disabled onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setInteractiveMode(e.target.checked) }} />} label="Interactive Mode" />
          <Button variant='contained' type="submit">Generate</Button>
        </FormControl>
      </Container>
    </form>
  )
};
