import { type IQuestion, Operator } from './Question'

export class DivisionQuestion implements IQuestion {
  readonly fact: number
  readonly num2: number
  readonly operator: Operator

  constructor (fact: number, num2: number) {
    this.operator = Operator.Division
    this.fact = fact
    this.num2 = num2
  }

  getAnswer (): number {
    return this.num2 / this.fact
  }
}
