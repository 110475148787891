import { type IQuestion, Operator } from '../../types/questions/Question'
import { AdditionQuestion } from '../../types/questions/AdditionQuestion'
import { SubtractionQuestion } from '../../types/questions/SubtractionQuestion'
import { MultiplicationQuestion } from '../../types/questions/MultiplicationQuestion'
import { DivisionQuestion } from '../../types/questions/DivisionQuestion'

export class QuestionGeneratorFactory {
  createQuestions (fact: number, operator: Operator, count: number): IQuestion[] {
    let generator: IQuestionGenerator
    switch (operator) {
      case Operator.Addition:
        generator = new AdditionQuestionGenerator(fact)
        break
      case Operator.Subtraction:
        generator = new SubtractionQuestionGenerator(fact)
        break
      case Operator.Multiplication:
        generator = new MultiplicationQuestionGenerator(fact)
        break
      case Operator.Division:
        generator = new DivisionQuestionGenerator(fact)
        break
      default:
        throw new Error('Operator not supported.')
    }

    return generator.generateQuestions(count)
  }
};

interface IQuestionGenerator {
  generateQuestions: (count: number) => IQuestion[]
}

class AdditionQuestionGenerator implements IQuestionGenerator {
  private readonly MAX_NUMBER: number = 10
  private readonly fact: number
  private readonly randomNumberGenerator: RandomNumberGenerator
  constructor (fact: number) {
    this.fact = fact
    this.randomNumberGenerator = new RandomNumberGenerator(this.MAX_NUMBER)
  }

  generateQuestions (count: number): IQuestion[] {
    const questions = []
    for (let i = 0; i < count; i++) {
      const num2 = this.randomNumberGenerator.generateRandomNumber()

      questions.push(new AdditionQuestion(this.fact, num2))
    }
    return questions
  }
}

class SubtractionQuestionGenerator implements IQuestionGenerator {
  private readonly fact: number
  private readonly randomNumberGenerator: RandomNumberGenerator
  constructor (fact: number) {
    this.fact = fact
    this.randomNumberGenerator = new RandomNumberGenerator(this.fact)
  }

  generateQuestions (count: number): IQuestion[] {
    const questions = []
    for (let i = 0; i < count; i++) {
      const num2 = this.randomNumberGenerator.generateRandomNumber()

      questions.push(new SubtractionQuestion(this.fact, num2))
    }
    return questions
  }
}

class MultiplicationQuestionGenerator implements IQuestionGenerator {
  private readonly MAX_NUMBER: number = 10
  private readonly fact: number
  private readonly randomNumberGenerator: RandomNumberGenerator
  constructor (fact: number) {
    this.fact = fact
    this.randomNumberGenerator = new RandomNumberGenerator(this.MAX_NUMBER)
  }

  generateQuestions (count: number): IQuestion[] {
    const questions = []
    for (let i = 0; i < count; i++) {
      const num2 = this.randomNumberGenerator.generateRandomNumber()

      questions.push(new MultiplicationQuestion(this.fact, num2))
    }
    return questions
  }
}

class DivisionQuestionGenerator implements IQuestionGenerator {
  private readonly MAX_NUMBER: number = 100

  private readonly fact: number
  private readonly randomNumberGenerator: RandomNumberGenerator
  constructor (fact: number) {
    this.fact = fact
    this.randomNumberGenerator = new RandomNumberGenerator(this.MAX_NUMBER)
  }

  generateQuestions (count: number): IQuestion[] {
    const questions = []
    for (let i = 0; i < count; i++) {
      let randomNumber = this.randomNumberGenerator.generateRandomNumber()

      while (randomNumber % this.fact !== 0) {
        randomNumber = this.randomNumberGenerator.generateRandomNumber()
      }

      questions.push(new DivisionQuestion(this.fact, randomNumber))
    }
    return questions
  }
}

class RandomNumberGenerator {
  private readonly usedNumbers = new Set<number>()
  private readonly range: number

  constructor (range: number) {
    this.range = range
  }

  generateRandomNumber (): number {
    if (this.usedNumbers.size === this.range) {
      this.usedNumbers.clear() // Reset the used numbers if we've returned all numbers.
    }

    let randomNumber: number
    do {
      randomNumber = Math.floor(Math.random() * this.range) + 1 // Keep number between 1 and Range
    } while (this.usedNumbers.has(randomNumber))

    this.usedNumbers.add(randomNumber)
    return randomNumber
  }
}
