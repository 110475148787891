import React from 'react'
import { Container, Box, Input, Typography, FormHelperText } from '@mui/material'

export default function NameDateScoreHeader (): JSX.Element {
  return (
        <Container
          id="question-form"
          maxWidth="md"
          sx={{
            display: 'none',
            flexDirection: 'row',
            alignItems: 'center',
            pb: 1,
            displayPrint: 'flex',
            justifyContent: 'center'
          }}>
      <Box
        sx={{
          minWidth: 120,
          pl: 1,
          pr: 1,
          textAlign: 'center'
        }}>
          <img src={require('../../assets/logo192.png')} alt="Race Car Math Logo" width={50} height={50} />
        <Typography variant='body2' align='center'>racecarmath.com</Typography>
        </Box>
        <Box
          sx={{
            minWidth: 280,
            pl: 2,
            pr: 2
          }}>
          <Input
                id="studentName"
                aria-describedby="studentName-helper-text"
                type="text"
                fullWidth={true}
            />
        <FormHelperText id="studentName-helper-text">Name</FormHelperText>
        </Box>
          <Box
              sx={{
                minWidth: 150,
                pl: 2,
                pr: 2
              }}>
              <Input
                  id="date"
                  aria-describedby="date-helper-text"
                  type="text"
                  fullWidth={true}
              />
            <FormHelperText id="date-helper-text">Date</FormHelperText>
          </Box>
          <Box
              sx={{
                minWidth: 100,
                pl: 2,
                pr: 2
              }}>
              <Input
                  id="score"
                  aria-describedby="score-helper-text"
                  type="text"
                  fullWidth={true}
              />
              <FormHelperText id="score-helper-text">Score</FormHelperText>
          </Box>
      </Container>

  /*
              <div className='name-date-score-container'>
                  <div className="student-info-field">
                      <div className="label">Name:</div>
                      <div className='line' />
                  </div>
                  <div className="student-info-field">
                      <div className="label">Date:</div>
                      <div className='line' />
                  </div>
                  <div className="student-info-field">
                      <div className="label">Score:</div>
                      <div className='line' />
                  </div>
              </div>
              <div className="domain-name">
                  racecarmath.com
              </div>
              */
  )
}
