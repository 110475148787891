import React from 'react'
import { alpha } from '@mui/material'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

export function Hero (): JSX.Element {
  return (
        <Box
        id="hero"
        sx={(theme) => ({
          width: '100%',
          backgroundImage:
          theme.palette.mode === 'light'
            ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
            : `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`,
          backgroundSize: '100% 20%',
          backgroundRepeat: 'no-repeat',
          displayPrint: 'none'
        })}>
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 6, sm: 8 },
          pb: { xs: 2, sm: 4 }
        }}
        >
        <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%' } }}>
          <Typography
            align="center"
            component="h2"
            variant="h2"
            >
            Welcome to
          </Typography>
          <Typography
          align="center"
            component="span"
            variant="h2"
            sx={{
              color: (theme) =>
                theme.palette.mode === 'light' ? 'primary.main' : 'primary.light'
            }}
          >
            Race Car Math!
          </Typography>
          <Typography variant="body1" textAlign="center" color="text.secondary">
                      Generate printable addition, substraction, multiplication, and division problems to practice.
                      Please specify the fact number and the number of questions you would like to generate (up to 100).
                      When you print this page, only the essential elements will remain on the page.
                      If you have feedback, please send it to <Link href="mailto:support@racecarmath.com" color="inherit">support@racecarmath.com</Link>.
          </Typography>
          </Stack>
      </Container>
    </Box>
  )
}
