import React from 'react'
import { Paper, Typography } from '@mui/material'

export function Footer (): JSX.Element {
  return (
        <Paper
            sx={{
              marginTop: 'calc(10% + 60px)',
              position: 'fixed',
              bottom: 0,
              displayPrint: 'none'
            }} component="footer" square variant="outlined">

            <Typography variant="caption">
              RaceCarMath.com &copy; {new Date().getFullYear()}
            </Typography>
        </Paper>
  )
};
