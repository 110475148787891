export interface IQuestion {
  operator: Operator
  fact: number
  num2: number
  getAnswer: () => number
}

export enum Operator {
  Addition = 'Addition',
  Subtraction = 'Subtraction',
  Multiplication = 'Multiplication',
  Division = 'Division',
}
